export default {
    roleName: 'User',
    permissionStatements: [
        {
            Effect: 'Allow',
            Action: [
                'ViewAccount',
                'UpdateAccount',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ViewDashboard',
                'ListUserSettings',
                'ListUserAgreements',
                'SignOut',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListMessages',
                'GetMessage',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListAliases',
                'GetAlias',
                'CreateAlias',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ListTags',
                'GetTag',
                'CreateTag',
                'UpdateTag',
                'DeleteTag',
            ],
            Resource: '*',
        },
    ],
};
