import {
    getMessage,
    listMessages
} from './queries';
import {
    createMessage,
    deleteMessage,
    updateMessage,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateMessage,
    DeleteMessage,
    GetMessage,
    ListMessages,
    Message,
    UpdateMessage
} from "../types.ts";
import {
    showErrorNotification,
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";

import {V6Client} from '@aws-amplify/api-graphql';
import {generateClient} from "aws-amplify/api";

let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (
    {
        tagId,
        status
    }: {
        tagId?: string,
        status?: string
    }) => {

    console.debug('IN FETCH RECORDS', tagId, status);

    let filter = {}
    if (typeof tagId !== 'undefined' && tagId !== null) {
        filter = {tagIds: {contains: tagId}};
    }
    if (typeof status !== 'undefined' && status !== null) {
        filter = {
            ...filter,
            ...{status: {eq: status}}
        };
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListMessages>>({
                                                                              query: listMessages,
                                                                              variables: {filter: filter},
                                                                          });
        return response.data?.listMessages.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetMessage>>({
                                                                            query: getMessage,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getMessage;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateMessage>>({
                                                                               query: createMessage,
                                                                               variables: {input: payload},
                                                                           });
        console.debug('in the service response', response);

        showSuccessNotification('Message created');
        return response.data?.createMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Message) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;
    delete record.tags;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateMessage>>({
                                                                               query: (updateMessage),
                                                                               variables: {input: record},
                                                                           });

        showSuccessNotification('Message updated');
        return response.data?.updateMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteMessage>>({
                                                                               query: (deleteMessage),
                                                                               variables: {input: {id: id}},

                                                                           });
        showSuccessNotification('Message deleted');
        return response.data?.deleteMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
