import {I18n} from '@aws-amplify/core';

import {
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';

import {useAccessControl} from '../../../hooks/useAccessControl';
import {useAuth} from "../../../hooks/useAuth.ts";
import {DeleteRecordButton} from '../buttons/DeleteRecordButton.tsx';
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useMessages();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};


const useTools = () => {

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (evaluateAction('DeleteMessage')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (evaluateAction('CreateMessage')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Messages')}
            tools={useTools()}
        />
    );
};