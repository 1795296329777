import {useNavigate} from 'react-router-dom';

import {Button} from '@mantine/core';

import {useAccessControl} from '../../../hooks/useAccessControl';
import {useToggle} from '@mantine/hooks';


import {AiOutlineProfile} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {useAuth} from "../../../hooks/useAuth.ts";
import {
    FlexiCloseButton,
    FlexiEditButton
} from "@flexinet/ui-components";

export const useEditPageTools = () => {

    const navigate = useNavigate();

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (evaluateAction('UpdateAlias')) {

        if (mode === 'edit') {
            tools.push(<Button radius='xs' variant={'filled'} key={'profile'}
                               leftSection={<IconContext.Provider value={{size: '24px'}}>
                                   <AiOutlineProfile/></IconContext.Provider>}
                               onClick={() => toggle()}>Profile</Button>);
        } else {
            tools.push(<FlexiEditButton key={'edit'} onClick={() => toggle()}/>);
        }

    }

    tools.push(<FlexiCloseButton key={'close'} onClick={() => navigate(`/aliases`)}/>);


    return {
        mode,
        tools,
    };

};
